import React from 'react';

import './About.css';

function About() {
  return (
    <section id="About">
      <h2>About</h2>
      <p>Marlee, Torin, and Aidan making bomb ass fried chicken sandos.</p>
    </section>
  );
}

export default About;
